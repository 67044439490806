<template>
  <div class="iss-main welcome">
    <div class="welcome-content mt-20 mb=20" v-if="!loading">
      <div class="welcome-title">欢迎来到人脉宝盒</div>
      <div class="welcome-text">快将您的联系人同步到人脉宝盒吧！</div>
      <img src="@/assets/images/dashboard/welcome.png" alt="welcome" />
      <div class="welcome-btn" @click="synchronization">一键同步手机联系人</div>
    </div>
    <div class="loading-content" v-else>
      <img src="@/assets/images/dashboard/loadingImg.png" alt="welcome" />
      <div class="loading">
        <a-spin /><span class="loading-text pl-20">同步中</span>
      </div>
    </div>
  </div>
  <!--  <a-modal-->
  <!--    v-model:visible="visible"-->
  <!--    :footer="null"-->
  <!--    :width="900"-->
  <!--    :bodyStyle="{-->
  <!--      background:-->
  <!--        'linear-gradient(360deg, #FDF6EC 0%, rgba(255,255,255,0) 64%)',-->
  <!--    }"-->
  <!--  >-->
  <!--    <div class="welcome-modal">-->
  <!--      <div class="welcome-modal-l">-->
  <!--        <div class="welcome-modal-title">-->
  <!--          <ScanOutlined /><span class="pl-8">扫码下载APP</span>-->
  <!--        </div>-->
  <!--        <div class="welcome-modal-text">一键同步手机联系人</div>-->
  <!--        <div class="qrcode-bg">-->
  <!--          <img src="@/assets/images/dashboard/qrcodeBg.png" alt="" />-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="welcome-modal-r">-->
  <!--        <img src="@/assets/images/dashboard/phone.png" alt="" />-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </a-modal>-->

  <open-app v-model:visible="visible" :init="initValue" @fnOk="handleOpenApp" />
</template>

<script>
// import { ScanOutlined } from '@ant-design/icons-vue';
import { ref, onBeforeUnmount, onMounted, reactive, toRefs } from 'vue';
import { Spin } from 'ant-design-vue';
import dashboardApi from '@/api/dashboard.js';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import openApp from '@/views/dashboard/components/openApp';

export default {
  name: 'welcome',
  components: {
    // ScanOutlined,
    ASpin: Spin,
    openApp,
  },
  setup() {
    const store = useStore();
    const loading = ref(false);
    const intervalId = ref(true);
    const router = useRouter();
    const state = reactive({
      visible: false,
    });
    const synchronization = () => {
      state.visible = true;
    };
    const getLoadingStatus = () => {
      dashboardApi
        .getStatus({
          userId: store.state.account.user.id,
        })
        .then(res => {
          // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成
          if (res) {
            if (res === 'start' || res === 'importing') {
              loading.value = true;
              state.visible = false;
            } else if (res === 'complete') {
              // clearInterval(intervalId.value);
              console.log('定时器已清除');
              router.push({
                path: '/dashboard',
                query: {
                  importStatus: 0,
                },
              });
            }
          }
        });
    };
    onMounted(() => {
      // intervalId.value = setInterval(() => {
      // console.log('定时器正在运行...');
      getLoadingStatus();
      // }, 5000);
    });
    // 在组件销毁之前清除定时器
    onBeforeUnmount(() => {
      // if (intervalId.value) {
      //   clearInterval(intervalId.value);
      //   console.log('定时器已清除');
      // }
    });

    return {
      loading,
      intervalId,
      ...toRefs(state),
      synchronization,
      handleOpenApp(value) {
        // if (intervalId.value) {
        //   clearInterval(intervalId.value);
        //   console.log('定时器已清除');
        // }
        state.visible = value;
      },
    };
  },
};
</script>

<style lang="less" scoped>
.welcome {
  display: flex;
  justify-content: center;
}
.welcome-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 500px;
  }
  .welcome-title {
    font-size: 40px;
    font-family: Alimama ShuHeiTi-Bold, Alimama ShuHeiTi;
    font-weight: 700;
    line-height: 46px;
    background: linear-gradient(90deg, #ff9c3f 0%, #ff7b00 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .welcome-text {
    padding: 16px 0 30px;
    font-size: 18px;
    font-weight: 400;
    color: #60473a;
    line-height: 24px;
    letter-spacing: 2px;
    -webkit-background-clip: text;
  }
  .welcome-btn {
    margin-top: 30px;
    padding: 20px 80px;
    background: linear-gradient(90deg, #fdbc25 0%, #ff8e31 38%, #ff7b00 87%);
    box-shadow: 0px 4px 8px 0px rgba(145, 70, 8, 0.24);
    border-radius: 100px;
    opacity: 1;
    cursor: pointer;
    font-size: 22px;
    font-family: Source Han Sans-Bold, Source Han Sans;
    font-weight: 700;
    color: #ffffff;
  }
}
.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 400px;
  }
  .loading {
    display: inline-block;
    color: @primary-color;
    font-size: 24px;
    margin-top: 30px;
    width: 220px;
    &:after {
      overflow: hidden;
      display: inline-block;
      padding-left: 6px;
      vertical-align: bottom;
      animation: ellipsis steps(9, end) 1200ms infinite;
      content: '\2026';
      width: 0px;
    }
  }
}
.welcome-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    width: 50%;
  }
  .welcome-modal-l {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .welcome-modal-title {
      font-size: 24px;
      font-family: Alimama ShuHeiTi-Bold, Alimama ShuHeiTi;
      font-weight: 700;
      color: #60473a;
      line-height: 24px;
    }
    .welcome-modal-text {
      font-size: 18px;
      font-family: Source Han Sans-Regular, Source Han Sans;
      font-weight: 400;
      color: #60473a;
      line-height: 24px;
      letter-spacing: 4px;
      margin: 20px 0 40px;
    }
    .qrcode-bg {
      width: 150px;
      padding: 10px;
      background: url('../../assets/images/dashboard/qrcodeBg.png') no-repeat
        center center;
      background-size: cover;
      img {
        width: 100%;
      }
    }
  }
  .welcome-modal-r {
    border-left: 1px solid #eeeeee;
    padding-left: 50px;
    img {
      width: 100%;
    }
  }
}
@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
</style>
